import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'
import VueSessionStorage from 'vue-sessionstorage'

Vue.use(VueRouter)

const routes = [
  {
    path:'/',
    name:"afcfta-caravan-price",
    component:()=>import('../views/prize_landing_page/index.vue') 
  },
  {
    path:'/laureates',
    name:"laureates",
    component:()=>import('../views/prize_landing_page/winners.vue') 
  },
  {
    path:'/winners',
    name:"winners",
    component:()=>import('../views/prize_landing_page/winners.vue') 
  },
  {
    path:'*',
    name:"404",
    component:()=>import('../components/PageNotFound.vue') 
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const ticket = to.query.ticket
  //   if(to.path === "/" && ticket) {
  //     store.dispatch('validateServiceTicket',ticket)
  //     next('/')  
  // }
  if (ticket) {
    store.dispatch('validateServiceTicket', ticket)
      .then(response => {
        if (response) {
          window.history.replaceState({}, document.title, window.location.origin + '/')
          window.location.reload()
        }
      })
  }

  next()
})
export default router
